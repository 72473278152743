<h1 id="title" class="title">Change password</h1>
<p class="sub-title">Please set a new password</p>



<div [formGroup]="resetPasswordForm" aria-labelledby="title">

  <div class="form-control-group">
    <label for="input-email">Email address:</label>
    <input  nbInput
            autofocus
            fullWidth
            fieldSize="large"
            id="input-email"
            formControlName="email"
            [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
            [attr.aria-invalid]="email.invalid && email.touched ? true : null"
            placeholder="Email address">
    <ngx-validation-message label="Email address" [showPattern]="email?.hasError('pattern') && email.touched"
                            [showRequired]="email?.errors?.required && email.touched"></ngx-validation-message>
  </div>

  <div class="form-control-group">
    <label for="input-password">Password:</label>
    <input  nbInput
            fullWidth
            id="input-password"
            formControlName="password"
            type="password"
            [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''"
            class="first"
            placeholder="Password">
    <ngx-validation-message label="Password" [showMinLength]="password?.hasError('minlength') && password.touched"
      [showMaxLength]="password?.hasError('maxlength') && password.touched"
      [showRequired]="password?.errors?.required && password.touched"
      [minLength]="minLength"
      [maxLength]="maxLength"></ngx-validation-message>
  </div>

  <div class="form-control-group">
    <label for="input-re-password">Confirm Password:</label>
    <input  nbInput
            fullWidth
            id="input-re-password"
            formControlName="confirmPassword"
            type="password"
            [status]="confirmPassword.dirty ? (confirmPassword.invalid || password.value != confirmPassword.value  ? 'danger' : 'success') : ''"
            placeholder="Password">
    <ngx-validation-message label="Confirm Password"
      [showMinLength]="confirmPassword?.hasError('minlength') && confirmPassword.touched"
      [showMaxLength]="confirmPassword?.hasError('maxlength') && confirmPassword.touched"
      [showRequired]="confirmPassword?.errors?.required && confirmPassword.touched"
      [minLength]="minLength"
      [maxLength]="maxLength"></ngx-validation-message>
    <p  class="error-message caption status-danger" *ngIf="password.value != confirmPassword.value && confirmPassword.touched">
      Password and confirm password does not match!
    </p>
  </div>

  <button nbButton status="success" fullWidth [disabled]="submitted || !resetPasswordForm.valid || password.value != confirmPassword.value"
    [class.btn-pulse]="submitted"
    (click)="resetPass()">
    Change password
  </button>
</div>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="../login">Back to Log In</a></p>
  <p><a class="text-link" routerLink="../register">Register</a></p>
</section>
