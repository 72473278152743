import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {ADMIN} from '../@core/data/data';
import {StorageService} from '../services/storage.service';

@Injectable()
export class AdminGuard implements CanActivate {
    constructor(private storageService: StorageService, private router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.storageService.loggedIn()) {
            if (this.storageService.getRole() === ADMIN) {
                return true;
            } else {
                this.router.navigate(['auth/login']);
            }
        } else {
            this.router.navigate(['auth/login']);
        }

    }
}
