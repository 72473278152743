import { BrowserModule } from '@angular/platform-browser';

import { NgModule, Injector, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppInjectorService } from './@core/mock/common/app-injector.service';
import { CoreModule } from './@core/core.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from './@theme/theme.module';
import { AuthModule } from './@auth/auth.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export const createTranslateLoader = (http: HttpClient) => {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

import {
    NbDatepickerModule,
    NbDialogModule,
    NbMenuModule,
    NbSidebarModule,
    NbToastrModule,
    NbWindowModule,
} from '@nebular/theme';
import { ApiService } from './services/api.service';
import { StorageService } from './services/storage.service';
import { AuthService } from './services/auth.service';
import { StoreModule } from '@ngrx/store';
import { reducers } from './@core/store/reducers';
import { UserEffects } from './@core/store/effects/user.effects';
import { AuthEffects } from './@core/store/effects/auth.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgCircleProgressModule } from 'ng-circle-progress';


@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AuthModule.forRoot(),
        NbSidebarModule.forRoot(),
        NbMenuModule.forRoot(),
        NbDatepickerModule.forRoot(),
        NbDialogModule.forRoot(),
        NbWindowModule.forRoot(),
        NbToastrModule.forRoot(),
        NgxPermissionsModule.forRoot(),
        StoreModule.forRoot(reducers, {}),
        EffectsModule.forRoot([AuthEffects, UserEffects]),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        CoreModule.forRoot(),
        ThemeModule.forRoot(),
        NgxSpinnerModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        NgCircleProgressModule.forRoot({
            'radius': 60,
            'space': -10,
            'outerStrokeGradient': true,
            'outerStrokeWidth': 10,
            'outerStrokeColor': '#4882c2',
            'outerStrokeGradientStopColor': '#53a9ff',
            'innerStrokeColor': '#e7e8ea',
            'innerStrokeWidth': 10,
            'animateTitle': false,
            'animationDuration': 1000,
            'showUnits': false,
            'showBackground': false,
            'clockwise': true,
            'startFromZero': true,
            'lazy': false
        }),
    ],
    exports: [StoreModule],
    bootstrap: [AppComponent],
    providers: [
        AuthService, StorageService, ApiService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
    constructor(injector: Injector) {
        AppInjectorService.injector = injector;
    }
}
