<h1 id="title" class="title">
    Register</h1>

<div [formGroup]="registerForm" aria-labelledby="title">
    <div class="form-control-group">
        <label for="input-name">Name:</label>
        <input nbInput autofocus fullWidth fieldSize="large" id="input-name" formControlName="name"
            [status]="name.dirty ? (name.invalid  ? 'danger' : 'success') : ''"
            [attr.aria-invalid]="name.invalid && name.touched ? true : null" placeholder="Name">
        <ngx-validation-message label="Name" [showMinLength]="name?.hasError('minlength') && name.touched"
            [showMaxLength]="name?.hasError('maxlength') && name.touched" [minLength]="minNameLength"
            [maxLength]="maxNameLength" [showRequired]="name?.errors?.required && name.touched">
        </ngx-validation-message>
    </div>

    <div class="form-control-group">
        <label for="input-name">Surname:</label>
        <input nbInput autofocus fullWidth fieldSize="large" id="input-surname" formControlName="surname"
            [status]="surname.dirty ? (surname.invalid  ? 'danger' : 'success') : ''"
            [attr.aria-invalid]="surname.invalid && surname.touched ? true : null" placeholder="Surname">
        <ngx-validation-message label="Surname" [showMinLength]="surname?.hasError('minlength') && surname.touched"
            [showMaxLength]="surname?.hasError('maxlength') && surname.touched" [minLength]="minNameLength"
            [maxLength]="maxNameLength" [showRequired]="surname?.errors?.required && surname.touched">
        </ngx-validation-message>
    </div>

    <div class="form-control-group">
        <label for="input-name">Organisation Name:</label>
        <input nbInput autofocus fullWidth fieldSize="large" id="input-company_name"
            formControlName="company_name"
            [status]="company_name.dirty ? (company_name.invalid  ? 'danger' : 'success') : ''"
            [attr.aria-invalid]="company_name.invalid && company_name.touched ? true : null"
            placeholder="Organisation Name">
        <ngx-validation-message label="Organisation Name"
            [showMinLength]="company_name?.hasError('minlength') && company_name.touched"
            [showMaxLength]="company_name?.hasError('maxlength') && company_name.touched"
            [minLength]="minNameLength" [maxLength]="minNameLength"
            [showRequired]="company_name?.errors?.required && company_name.touched">
        </ngx-validation-message>
    </div>

    <!-- <div class="form-control-group">
        <label for="input-name">Subdomain Name:</label>
        <input nbInput autofocus fullWidth fieldSize="large" id="subdomain-name" (blur)="checkDomain()" formControlName="domainName" [status]="domainName.dirty ? (domainName.invalid  ? 'danger' : 'success') : ''" [attr.aria-invalid]="domainName.invalid && domainName.touched ? true : null"
            placeholder="Domain Name">
        <ngx-validation-message label="Domain Name" [showMinLength]="domainName?.hasError('minlength') && domainName.touched" [showMaxLength]="domainName?.hasError('maxlength') && domainName.touched" [minLength]="minDomainNameLength" [maxLength]="minDomainNameLength"
            [showRequired]="domainName?.errors?.required && domainName.touched">
        </ngx-validation-message>
    </div> -->

    <div class="form-control-group">
        <label for="input-name">Phone:</label>
        <input nbInput autofocus fullWidth (keypress)="numberOnly($event)"  fieldSize="large" id="input-phone" formControlName="country_phone_code"
        [status]="country_phone_code.dirty ? (country_phone_code.invalid  ? 'danger' : 'success') : ''"
        [attr.aria-invalid]="country_phone_code.invalid && country_phone_code.touched ? true : null" placeholder="Country Phone Code">
        <input nbInput autofocus fullWidth (keypress)="numberOnly($event)" [maxLength]="maxPhoneLength"
            fieldSize="large" id="input-phone" formControlName="phone"
            [status]="phone.dirty ? (phone.invalid  ? 'danger' : 'success') : ''"
            [attr.aria-invalid]="phone.invalid && phone.touched ? true : null" placeholder="Phone">
        <ngx-validation-message label="Phone" [showMinLength]="phone?.hasError('minlength') && phone.touched"
            [showMaxLength]="phone?.hasError('maxlength') && phone.touched" [minLength]="minPhoneLength"
            [maxLength]="maxPhoneLength" [showRequired]="phone?.errors?.required && phone.touched">
        </ngx-validation-message>
    </div>

    <div class="form-control-group">
        <label for="input-email">Email address:</label>
        <input nbInput fullWidth fieldSize="large" id="input-email" formControlName="email"
            [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
            [attr.aria-invalid]="email.invalid && email.touched ? true : null" placeholder="Email address">
        <ngx-validation-message label="Email address" [showPattern]="email?.hasError('pattern') && email.touched"
            [showRequired]="email?.errors?.required && email.touched"></ngx-validation-message>
    </div>

    <div class="form-control-group">
        <label for="input-password">Password:</label>
        <input nbInput fullWidth fieldSize="large" id="input-password" formControlName="password" type="password"
            [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''" placeholder="Password">
        <ngx-validation-message label="Password" [showMinLength]="password?.hasError('minlength') && password.touched"
            [showMaxLength]="password?.hasError('maxlength') && password.touched"
            [showRequired]="password?.errors?.required && password.touched" [minLength]="minLength"
            [maxLength]="maxLength"></ngx-validation-message>
    </div>

    <div class="form-control-group">
        <label for="input-re-password">Confirm Password:</label>
        <input nbInput fullWidth fieldSize="large" id="input-re-password" formControlName="password_confirmation"
            type="password"
            [status]="password_confirmation.dirty ? (password_confirmation.invalid || password.value != password_confirmation.value  ? 'danger' : 'success') : ''"
            placeholder="Password">
        <ngx-validation-message label="Confirm Password"
            [showMinLength]="password_confirmation?.hasError('minlength') && password_confirmation.touched"
            [showMaxLength]="password_confirmation?.hasError('maxlength') && password_confirmation.touched"
            [showRequired]="password_confirmation?.errors?.required && password_confirmation.touched"
            [minLength]="minLength" [maxLength]="maxLength"></ngx-validation-message>
        <p class="caption status-danger" *ngIf="password.value != password_confirmation.value">
            Password and confirm password does not match!
        </p>
    </div>

    <div class="form-control-group accept-group" *ngIf="term">
        <nb-checkbox formControlName="terms" [required]="term">
            Agree to <a href="#" target="_blank"><strong>Terms & Conditions</strong></a>
        </nb-checkbox>
    </div>

    <button nbButton fullWidth status="primary" size="large" [disabled]="submitted || !registerForm.valid || !terms?.value
            || password.value != password_confirmation.value" [class.btn-pulse]="submitted" (click)="register()">
        Register
    </button>
</div>

<!-- <section class="another-action" aria-label="Sign in" *ngIf="isDomain">
    Already have an account? <a class="text-link" routerLink="../login">Log in</a>
</section> -->