
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserData } from '../../interfaces/common/users';

import { UsersService } from './users.service';
import { SettingsData } from '../../interfaces/common/settings';
import { SettingsService } from './settings.service';
import { AppInjectorService } from './app-injector.service';
import { MessageService } from './message.service';
import { ApiService } from './api.service';
import { StatusReportsData } from '../../interfaces/common/statusReports';
import { OrganisationService } from './organisation.service';
import { PackageService } from './package.service';

const SERVICES = [
    { provide: UserData, useClass: UsersService },
    { provide: SettingsData, useClass: SettingsService },
    { provide: AppInjectorService, useClass: AppInjectorService },
    { provide: ApiService, useClass: ApiService },
    { provide: MessageService, useClass: MessageService },
    { provide: OrganisationService, useClass: OrganisationService },
    { provide: PackageService, useClass: PackageService },
];

@NgModule({
    imports: [CommonModule],
})
export class CommonMockModule {
    static forRoot(): ModuleWithProviders<CommonMockModule> {
        return {
            ngModule: CommonMockModule,
            providers: [
                ...SERVICES,
            ],
        };
    }
}
