<h1 id="title" class="title">Change Email</h1>

<div [formGroup]="changeMailForm" aria-labelledby="title">

    <div class="form-control-group">
        <label for="input-email">New Email Address:</label>
        <input  nbInput
                autofocus
                fullWidth
                fieldSize="large"
                id="input-email"
                formControlName="email"
                [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
                [attr.aria-invalid]="email.invalid && email.touched ? true : null"
                placeholder="Email address">
        <ngx-validation-message label="Email address" [showPattern]="email?.hasError('pattern') && email.touched"
          [showRequired]="email?.errors?.required && email.touched"></ngx-validation-message>
      </div>


  <button nbButton
          fullWidth
          status="primary"
          size="large"
          [disabled]="submitted || !changeMailForm.valid"
          [class.btn-pulse]="submitted"
          (click) = "onSubmit()">
  Submit
  </button>
</div>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="../login">Back to Log In</a></p>
  <p><a routerLink="../register" class="text-link">Register</a></p>
</section>

