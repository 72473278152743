import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DEMO_GAME_COUNT, FAST_GAME, NAME } from '../../data/data';

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    private messageSource = new BehaviorSubject('');
    statusDialog = this.messageSource.asObservable();
    moduleSourceIds = [
        { 'moduleId': null },
        { 'sourceId': null }
    ];
    private messageSource2 = new BehaviorSubject('');
    packageData = this.messageSource2.asObservable();


    constructor() {
    }

    sendDialogMessage(message: any) {

        this.messageSource.next(message);

    }

    sendPackageData(message: any) {

        this.messageSource2.next(message);

    }

}
