import {Injectable} from '@angular/core';
import {USER, ADMIN, DEMO} from '../@core/data/data';
import {Router} from '@angular/router';
import {Route} from '../@core/data/route';
import {environment} from '../../environments/environment';


@Injectable()
export class StorageService {

    constructor(private router: Router) {
    }


    clearStorage() {
        localStorage.clear();
    }

    setUser(user) {
        localStorage.setItem('user', user);
    }

    getUser() {
        return localStorage.getItem('user');
    }

    removeUser() {
        localStorage.removeItem('user');
    }

    setRole(role) {
        localStorage.setItem('role', role);
    }

    getRole() {
        if (this.payload(this.getToken())) {
            const role = this.payload(this.getToken());
            return role.scopes[0];
        }
        // return localStorage.getItem('role');
    }

    removeRole() {
        localStorage.removeItem('role');
    }

    getDomain() {
        return localStorage.getItem('domain');
    }

    setDomain(domain: string) {
        localStorage.setItem('domain', domain);
    }

    removeDomain() {
        localStorage.removeItem('domain');
    }

    getConfirmCode() {
        return localStorage.getItem('confirmation_code');
    }

    setConfirmCode(confirmation_code: string) {
        localStorage.setItem('confirmation_code', confirmation_code);
    }

    removeConfirmCode() {
        localStorage.removeItem('confirmation_code');
    }

    getToken() {
        return localStorage.getItem('token');
    }

    setToken(token) {
        localStorage.setItem('token', token);
    }

    removeToken() {
        localStorage.removeItem('token');
    }

    isValid() {
        const token = this.getToken();
        if (token) {
            const payload = this.payload(token);
            if (payload) {
                return true;
            }
        }
        return false;
    }

    getSubdomain() {

        const x = window.location.hostname;
        const y = x.split('.');

        if (y[0] === 'www') {
            return y[1];
        } else if (y[1] === environment.domainUrl) {
            return y[0];
        } else {
            return false;
        }
    }


    payload(token) {
        if (token) {
            const payload = token.split('.')[1];
            return this.decode(payload);
        } else {
            return false;
        }

    }

    decode(payload) {
        if (payload) {
            return JSON.parse(atob(payload));
        }
    }

    loggedIn() {
        return this.isValid();
    }


    handleAllRole(role) {

        if (role === USER) {
            this.router.navigateByUrl(Route.DASHBOARD);
        }
        if (role === DEMO) {
            this.router.navigateByUrl(Route.DASHBOARD);
        } else if (role === ADMIN) {
            this.router.navigateByUrl(Route.DASHBOARD);
        }

    }
}
