import {Injectable} from '@angular/core';
import {Observable, Subject, of} from 'rxjs';
import {ApiService} from './api.service';
import {Route} from '../../data/route';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {ResponseType} from '../../model/response-type';


@Injectable({
    providedIn: 'root'
})
export class OrganisationService {


    constructor(private apiService: ApiService) {
    }


    getOrganisations(page: any = null, limit: number = 12): Observable<any> {
        if (page === null) {
            return this.apiService.get(`${Route.ORGANISATIONS}`).pipe(catchError(this.formatError));
        } else {
            return this.apiService.get(`${Route.ORGANISATIONS}` + '?page=' + page + '&limit=' + limit)
                .pipe(catchError(this.formatError));
        }
    }

    formatError(error: any) {
        return of(environment.apiUrl + error.error);
    }


}

