export const Route = {
    ADMIN: {
        GET_ALL: 'admin/getAllUser',
        PROFILE: '/admin/profile'
    },
    GET_USER: '/',
    PROFILE: '/profile',
    UPLOAD: '/media',
    DELETE_FILE: '/games/delete-file-game',
    DELETE: '/games/delete',
    TAGS: '/tags',
    THEMES: '/themes',
    PACKAGES: '/packages',
    PACKAGES_BOUGHT: '/payment-history',
    CATEGORIES: '/categories',
    GIFTS: '/gifts',
    GAMES: '/games',
    ORGANISATIONS: '/organisations',
    ACCOUNT: '/accounts',
    ACCOUNT_SECURITY: '/accounts/update-security',
    SCORM_DOWNLOAD: '/scorm/download',
    CHECK_GAME: '/check-game',
    LEARNER: '/get-learners',
    USERGAMES: '/get-user-games',
    USERS: '/users',
    REPORT: {
        ORGANISATION_GENERAL_REPORT: '/report/general-report',
        GAME_REPORT: '/report/game-report',
        GAME_DETAIL_REPORT: '/report/game-detail-report',
        BEST_LIST_REPORT: '/report/best-list-report',
        CATEGORY_GENERAL_REPORT: '/report/category-general-report',
        QUESTION_GENERAL_REPORT: '/report/question-general-report',
        LEARNER_GENERAL_REPORT: '/report/learner-general-report',
    }
    ,
    PUBLIC: {
        GET: '/getPublic',
        LOGIN: '/auth/login',
        REGISTER: '/register'
    },
    DASHBOARD: '/dashboard'


};
